import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { Constants } from './../utils/constants';

const AddVehicle = () => {

  const [formData, setFormData] = useState({
    name: '',
    vehicleNumber: '',
    passNo: '',
    remarks: '',
    recommendedBy: '',
  });

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileUpload = () => {

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const sheet = workbook.Sheets[workbook.SheetNames[0]];

        const jsonData = XLSX.utils.sheet_to_json(sheet);

        console.log(jsonData);
        console.log(jsonData.length, "sada")
        if (jsonData.length < 1) {

          return toast('Sheet is Blank');
        } else {

          const newData = convertData(jsonData);
          let response;
          (async () => {
            response = await axios.post(
              `${Constants.apiUrl}/api/register/file`,
              newData
            );
          })();

          console.log(response, 'ressss');
          toast('Form Submitted Successfully');
        }
      };
      document.getElementById('fileupload').value = '';

      reader.readAsArrayBuffer(file);
    }
  };

  const fieldMapping = {
    'Name': 'name',
    'Pass Number': 'passNo',
    'Recommended By': 'recommendedBy',
    'Remarks': 'remarks',
    'Vehicle Number': 'vehicleNumber',
  };

  function convertData(data) {
    return data.map((item) => {
      const newItem = {};
      for (const key in item) {
        if (fieldMapping[key]) {
          newItem[fieldMapping[key]] = item[key];
        }
      }
      return newItem;
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${Constants.apiUrl}/api/register`,
        formData
      );
      if (response.data.success) {
        console.log('Registration successful');
        toast('Form Submitted Successfully');
        setFormData({
          name: '',
          vehicleNumber: '',
          passNo: '',
          remarks: '',
          recommendedBy: '',
        });
      } else {
        toast('Registration Failed');
        console.error('Registration failed');
      }
    } catch (error) {
      toast('Registration Failed');
      console.error('An error occurred:', error);
    }
  };

  return (
    <>

      <div className='wrapper mb-3'>
        <div className='contain'>
          <div className='titlbar'>
            <h4 className='text-center'>Upload Vehicle Excel</h4>
          </div>
          <input
            className='form-control mb-3'
            type="file"
            id="fileupload"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          <div className="form-group">
            <button className='btn btn-primary' onClick={handleFileUpload}>Submit</button>
          </div>
          <a download href='/vehicles.xlsx'>Download Sample Excel</a>
        </div>
      </div>

      <div className='wrapper'>
        <div className='contain'>
          <div className='titlbar'>
            <h4 className='text-center'>Add Vehicle Maually</h4>
          </div>

          <form action="#" method="POST" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="vehicle">Vehicle Number</label>
              <input required className='form-control' placeholder='Enter Vehicle No.' type="text" id="vehicleNumber" name="vehicleNumber"
                value={formData.vehicleNumber}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input className='form-control' placeholder='Enter Person Name' type="text" id="name"
                value={formData.name}
                onChange={handleChange}
                name="name" />
            </div>
            <div className="form-group">
              <label htmlFor="pass">Pass Number</label>
              <input className='form-control'
                type="text"
                id="pass"
                name="passNo"
                value={formData.passNo}
                onChange={handleChange}
                placeholder="Enter Pass no."
              />
            </div>
            <div className="form-group">
              <label htmlFor="remarks">Remarks</label>
              <input className='form-control'
                type="text"
                id="remarks"
                name="remarks"
                value={formData.remarks}
                onChange={handleChange}
                placeholder="Enter Remarks"
              />
            </div>
            <div className="form-group">
              <label htmlFor="recommendedBy">Recommended By</label>
              <input className='form-control'
                type="text"
                id="recommendedBy"
                name="recommendedBy"
                value={formData.recommendedBy}
                onChange={handleChange}
                placeholder="Enter Recommended By"
              />
            </div>

            <button className="btn btn-primary" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddVehicle;
