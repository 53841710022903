import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './Components/layouts/MainLayout';
import AddVehicle from './page/AddVehicle';
import Vehicles from './page/Vehicles';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route Component={MainLayout} >
            <Route path='/' Component={Vehicles} />
            <Route path='/vehicles' Component={Vehicles} />
            <Route path='/add-vehicle' Component={AddVehicle} />
            <Route path='*' element={<Navigate to={"/"} />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
