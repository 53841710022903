import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ToastContainer } from 'react-toastify';
import { Link, Outlet } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const MainLayout = (props) => {
    return (
        <>
            <Navbar bg="primary" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand as={Link} to="/vehicles">Vehile Logger</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link as={Link} to="/vehicles">Vehciles</Nav.Link>
                            <Nav.Link as={Link} to="/add-vehicle">Add New Vehcile</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <main>
                <Container>
                    <Outlet />
                </Container>
            </main>
            <ToastContainer />
        </>
    );
};

export default MainLayout;
