import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Constants } from '../utils/constants';

const Vehicles = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [recordedResults, setRecordedResults] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      axios.get(`${Constants.apiUrl}/api/registrations`, {
        params: { searchTerm: searchQuery },
      })
        .then((response) => {
          setSearchResults(response.data.data);
          console.log(response.data.data, "dataa");
        })
        .catch((error) => {
          console.error('Error fetching search results:', error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);
  const [data, setData] = useState([]);
  let history = useNavigate();
  const handleRecordClick = (resultId) => {
    axios.post(`${Constants.apiUrl}/api/record`, { vehicle: resultId })
      .then((response) => {
        // Update the recorded results state with the saved result ID
        setRecordedResults([...recordedResults, resultId]);
        toast("Recorded Successfully")

      })
      .catch((error) => {
        console.error('Error recording result:', error);
      });
  };

  return (
    <>
      <div className='wrapper'>
        <div className='contain'>
          <div className='titlbar'>
            <h4 className='text-center'>Search Vehicle Entries</h4>
          </div>
          <div className='searchbar'>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="table-container table-responsive">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Vehicle Number🚗</th>
                  <th>Pass Number</th>
                  <th>Remarks</th>
                  <th>Recommended By</th>
                  <th>Record</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.vehicleNumber}</td>
                    <td>{item.passNo}</td>
                    <td>{item.remarks}</td>
                    <td>{item.recommendedBy}</td>
                    <td>
                      {/* {!recordedResults.includes(item._id) && (  */}
                      <button className='btn btn-primary btn-sm' onClick={() => handleRecordClick(item._id)}>Record</button>
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </>
  );
};

export default Vehicles;
